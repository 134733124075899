import React, { useReducer, createContext } from "react";
import { TOGGLE_UNITS, SET_LOCATION, SET_CITY } from "./constants";
import store from "store";

const initialLocation = "Dallas, TX";
const storeLocation = store.get("location");

const initialUnits = "imperial";
const storeUnits = store.get("units");

const hours = new Date().getHours();
const isDay = hours > 6 && hours < 20;

const initialState = {
  location: storeLocation || initialLocation,
  city: initialLocation,
  country: "",
  units: storeUnits || initialUnits,
  isDay: isDay,
};

function authReducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case TOGGLE_UNITS:
      return {
        ...state,
        units: payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: payload,
      };
    case SET_CITY:
      return {
        ...state,
        city: payload.city,
        country: payload.country,
      };
    default:
      return state;
  }
}

const WeatherContext = createContext({});

function WeatherProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const toggleUnits = ({ units }) => {
    const newUnits = units === "imperial" ? "metric" : "imperial";
    store.set("units", newUnits);
    dispatch({
      type: TOGGLE_UNITS,
      payload: newUnits,
    });
  };

  const setLocation = ({ location }) => {
    dispatch({
      type: SET_LOCATION,
      payload: location,
    });
  };

  const setCity = ({ city, country, location }) => {
    store.set("location", location);
    dispatch({
      type: SET_CITY,
      payload: { city, country },
    });
  };

  return (
    <WeatherContext.Provider
      value={{
        units: state.units,
        location: state.location,
        city: state.city,
        country: state.country,
        toggleUnits,
        setLocation,
        setCity,
        isDay,
      }}
      {...props}
    />
  );
}

export { WeatherContext, WeatherProvider };
