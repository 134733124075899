import React from "react";
import PropTypes from "prop-types";
import { LayoutWrapper } from "./wrappers";

const Layout = ({ children, isDay }) => {
  return <LayoutWrapper isDay={isDay}>{children}</LayoutWrapper>;
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isDay: PropTypes.bool,
};

export default Layout;
