import styled from "styled-components";
import { Box } from "rebass";

export const EasterEggWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  transition: .3s transform ease-in-out;
  transform: ${(props) => (!!props.isCheatsActive ? 'translateX(0) rotate(0)': 'translateX(300px) rotate(45deg)')};
  img {
    max-width: 240px;
  }
`;
