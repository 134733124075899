import styled, { css, keyframes } from "styled-components";
import { Flex } from "rebass";
import { motion } from 'framer-motion';
import mq from "utils/mq";

export const AppMountAnimation = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Display = styled(Flex)`
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  margin:0 auto;
  flex-direction: column;
  width: 100%;
  max-width: 670px;
  justify-content: center;
  alignItems: center;

  img {
    width: 100%;
    ${mq(null, "s")(css`
      display: none;
    `)}
  }
`;

const floatingAnimation1 = keyframes`
  0% { transform: translate(0,  0); }
  50%  { transform: translate(20px, 0); }
  100%   { transform: translate(0, 0); }    
`;

const floatingAnimation2 = keyframes`
  0% { transform: translate(0,  0); }
  50%  { transform: translate(-20px, 0); }
  100%   { transform: translate(0, 0); }    
`;

export const CityContainer = styled(Flex)`
  position: relative;
  ${mq(null, 's')(css`
    justify-content: space-between;
  `)}
  .cloud-left {
    position: absolute;
    left: -77px;
    max-width: 154px;
    top: 40%;
    animation: ${floatingAnimation1} 20s ease-in-out infinite;
  }
  .cloud-right {
    position: absolute;
    right: -107px;
    max-width: 214px;
    top: 20%;
    animation: ${floatingAnimation2} 20s ease-in-out infinite;
    animation-delay: 0.6s;
  }
`;
