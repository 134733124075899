import { format } from "date-fns";

const getDate = (utc) => {
  const epoch = new Date(0);
  const date = epoch.setUTCSeconds(utc);
  const d = new Date(date);
  return d;
};

const getDay = (dt) => {
  const date = getDate(dt);
  return format(date, "E");
};

const getMobileDay = (dt) => {
  const date = getDate(dt);
  return format(date, "EEEE");
};

const groomForecastData = (weatherForecast) => {
  const forecast = weatherForecast.map((f) => ({
    day: getDay(f.dt),
    mobileDay: getMobileDay(f.dt),
    temp: (f.temp.max + f.temp.min) / 2,
    description: f.weather[0].description
  }));

  return forecast
};

export default groomForecastData;
