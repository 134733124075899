import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Flex } from "rebass";
import mq from "utils/mq";

export const LoadingContainer = styled(Flex)`
  min-height: 112px;
  padding: 26px 28px;
  ${mq(
    null,
    "s"
  )(css`
    min-height: 154px;
  `)}
`;

export const CurrentWeatherWrapper = styled(Flex)`
  padding: 26px 28px;
  color: #65aed5;
  min-height: 112px;
  position: absolute;
  top: 0;
  left: 0;
  ${mq(
    null,
    "s"
  )(css`
    min-height: 154px;
    position: static;
    color: #ffffff;
    width: 100%;
  `)}
`;

export const CurrentWeatherAnimator = styled(motion.div)`
  display: flex;
  flex-direction: row;
  ${mq(
    null,
    "s"
  )(css`
    color: #ffffff;
    flex-direction: column;
  `)}
`;

export const TextContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
  ${mq(
    null,
    "s"
  )(css`
    padding-top: 2px;
    padding-left: 0;
  `)}
`;

export const Temp = styled.h1`
  font-size: 48px;
  font-weight: 400;
  line-height: 1;
  .degree {
    font-size: 40px;
  }
  }
`;

export const IconContainer = styled(Flex)`
  svg {
    color: inherit;
    transform: translateX(-4px);
    ${mq(
      null,
      "s"
    )(css`
      color: #ffffff;
    `)}
  }
`;

const descriptionVariant = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

export const Desc = styled.p`
  ${descriptionVariant};
`;

export const Wind = styled.p`
  ${descriptionVariant};
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  color: #ffffff;
`;
