import styled from 'styled-components';
import { Flex } from 'rebass';

export const WeatherIconWrapper = styled(Flex)`
  margin: 16px 0;
  justify-content: center;
  align-items: center;
  svg {
    color: ${props => props.color || "#65AED5"};
    width: 56px;
    height: 56px;
  }
`;