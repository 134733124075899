import "./App.css";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import EasterEgg from "components/EasterEgg";
import HomePage from "pages/Home";
import { WeatherProvider } from "context";

const history = createBrowserHistory();

const App = () => {
  return (
    <WeatherProvider>
      <Router history={history}>
        <Switch>
          <Route component={HomePage} />
        </Switch>
      </Router>
      <EasterEgg />
    </WeatherProvider>
  );
};

export default App;
