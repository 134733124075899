import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import PropTypes from "prop-types";
import { Flex } from "rebass";
import { WeatherContext } from "context";
import identifyIcon from "utils/identifyIcon";
import isZipCode from "utils/isZipCode";
import WeatherIcon from "components/WeatherIcon";
import PATHS from "./paths";
import {
  CurrentWeatherWrapper,
  CurrentWeatherAnimator,
  TextContainer,
  Temp,
  IconContainer,
  Desc,
  Wind,
} from "./wrappers";
import { containerAnimation } from "./animations";

const CurrentWeather = ({ className }) => {
  const { units, location, setCity } = useContext(WeatherContext);
  const [currentWeather, setCurrentWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(false);
    setLoading(true);
    setCurrentWeather(null);

    // dynamic logic to handle either zipcode or location name
    let locationQuery, path;
    if (isZipCode(location)) {
      locationQuery = location;
      path = PATHS.byZipCode({ locationQuery, units });
    } else {
      const [city, state, country] = location.split(",");
      locationQuery = `${city}${!!state && "," + state}${
        !!country && "," + country
      }`;
      path = PATHS.byLocationName({ locationQuery, units });
    }

    const getCurrentInfo = () => {
      axios
        .get(path)
        .then((response) => response.data)
        .then((data) => {
          setCurrentWeather(data);
          setCity({
            city: data.name,
            country: data.sys.country,
            location,
          });
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    };
    getCurrentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, units]);

  const formatDescription = (str) =>
    str
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.slice(1))
      .join(" ") || "";

  const description = !!currentWeather
    ? formatDescription(currentWeather.weather[0].description)
    : "";

  const wind = currentWeather?.wind?.speed || "";

  const temperature =
    units === "imperial"
      ? Math.round(currentWeather?.main?.temp)
      : Math.round(currentWeather?.main?.temp * 10) / 10;

  const icon = !!currentWeather
    ? identifyIcon(currentWeather.weather[0].description)
    : "";

  const isLoading = Boolean(loading && !error);
  const isError = Boolean(!loading & !!error);
  const isReady = Boolean(!loading && !error);

  return (
    <CurrentWeatherWrapper className={className}>
      {/* IF LOADING */}
      {isLoading && (
        <Flex justifyContent="center" alignItems="center">
          <Desc>Loading...</Desc>
        </Flex>
      )}

      {/* IF SUCCESS */}
      {isReady && (
        <CurrentWeatherAnimator
          variants={containerAnimation}
          initial="hidden"
          animate="show"
        >
          <Flex>
            <Flex>
              <Temp>
                {temperature || "0"}
                <span className="degree">°</span>
              </Temp>
            </Flex>
            <IconContainer>
              <WeatherIcon icon={icon} />
            </IconContainer>
          </Flex>
          <TextContainer>
            <Desc>{description}</Desc>
            <Wind>
              {wind}
              {units === "imperial" ? " mph" : " m/s"}
            </Wind>
          </TextContainer>
        </CurrentWeatherAnimator>
      )}

      {/* IF ERROR OCCURS */}
      {isError && (
        <Flex pr="60px">
          <Desc>
            Zip code / Location not found. Please try a different location.
          </Desc>
        </Flex>
      )}
    </CurrentWeatherWrapper>
  );
};

CurrentWeather.propTypes = {
  className: PropTypes.string,
};

export default CurrentWeather;
