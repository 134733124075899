import styled, { css } from "styled-components";
import mq from "utils/mq";

export const LayoutWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: ${(props) =>
    props.isDay
      ? "linear-gradient(45deg, rgba(17,93,163,1) 0%, rgba(78,207,237,1) 100%)"
      : "linear-gradient(45deg,rgb(21 31 41) 0%,rgb(42 52 86) 100%)"};
  box-sizing: border-box;
  padding: 20px;
  ${mq(
    null,
    "s"
  )(css`
    justify-content: flex-start;
  `)}
`;
