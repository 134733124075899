import React, { useContext } from "react";
import { WeatherContext } from "context";
import {
  ToggleWrapper,
  ToggleContainer,
  TempContainer,
  Temp,
  Circle,
} from "./wrappers";
import PropTypes from "prop-types";

const ToggleButton = ({ className }) => {
  const { units, toggleUnits } = useContext(WeatherContext);
  const onChange = () => toggleUnits({ units });

  return (
    <ToggleWrapper onClick={onChange} className={className}>
      <ToggleContainer>
        <Circle isRight={units === "imperial"} />
        <TempContainer justifyContent="center" alignItems="center">
          <Temp isActive={units === "metric"}>C<span className="degree">°</span></Temp>
        </TempContainer>
        <TempContainer justifyContent="center" alignItems="center">
          <Temp isActive={units === "imperial"}>F<span className="degree">°</span></Temp>
        </TempContainer>
        <input
          type="checkbox"
          aria-label="Toggle Button"
          checked={units === "imperial"}
          onChange={onChange}
        />
      </ToggleContainer>
    </ToggleWrapper>
  );
};

ToggleButton.defaultProps = {};

ToggleButton.propTypes = {
  className: PropTypes.string,
};

export default ToggleButton;
