import styled, { css } from "styled-components";
import { Flex, Box } from "rebass";
import { motion } from "framer-motion";
import mq from "utils/mq";

export const TitleWrapper = styled(Flex)`
  color: #ffffff;
  flex-direction: column;
  width: 100%;
  max-width: 670px;
  align-items: center;
  padding-bottom: 24px;
  ${mq(
    null,
    "s"
  )(css`
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 18px;
  `)}
`;

export const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${mq(
    null,
    "s"
  )(css`
    width: 100%;
  `)}
`;

export const TitleAnimator = styled(motion.div)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const PinDropContainer = styled(Flex)`
  padding-right: 4px;
  ${mq(
    null,
    "s"
  )(css`
    padding-right: 8px;
  `)}
  svg {
    ${mq(
      null,
      "s"
    )(css`
      width: 20px;
      height: 20px;
    `)}
`;

const cityTextStyles = css`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  color: #ffffff;
`;

export const City = styled.h1`
  ${cityTextStyles}
  padding: 4px 0 4px 2px;
  cursor: pointer;
  transition: 0.3s opacity ease-in-out;
  opacity: ${(props) => (props.isEditing ? "0" : "1")};
  width: auto;
  ${mq(
    null,
    "s"
  )(css`
    font-size: 28px;
    width: 100%;
    text-align: left;
  `)}
`;

export const FormContainer = styled(Flex)`
  width: 100%;
  position: relative;
`;

export const Form = styled.form`
  width: auto;
  display: flex;
  height: 100%;
  min-width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s opacity ease-in-out;
  opacity: ${(props) => (props.isEditing ? "1" : "0")};
  pointer-events: ${(props) => (props.isEditing ? "auto" : "none")};
  ${mq(
    null,
    "s"
  )(css`
    width: 100%;
  `)}
  input {
    ${cityTextStyles}
    height: 100%;
    text-align: left;
    border: none;
    box-sizing: border-box;
    background: transparent;
    outline: none;
    width: auto;
    font-family: "Open Sans";
    ${mq(
      null,
      "s"
    )(css`
      width: calc(100% - 30px);
      font-size: 28px;
      padding: 0 0 0 2px;
    `)}
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    opacity: ${(props) => (props.isEditing ? "1" : "0")};
  }
  button[type="submit"] {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
    display: flex;
    z-index: 1;
    cursor: pointer;
    color: #ffffff;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CityContainer = styled(Flex)`
  flex-direction: center;
  align-items: center;
  width: auto;
  ${mq(
    null,
    "s"
  )(css`
    width: 100%;
  `)}
`;

export const DateContainer = styled(Box)`
  padding-top: 4px;
  width: 100%;
`;

export const DateText = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 1;
  ${mq(
    null,
    "s"
  )(css`
    font-size: 16px;
    text-align: left;
  `)}
`;
