import React, { useContext } from "react";
import Layout from "components/Layout";

import dallas from "images/dallas.png";
import Title from "components/Title";

import ForecastList from "components/ForecastList";
import CurrentWeather from "components/CurrentWeather";
import Toggle from "components/Toggle";
import CloudLeft from "images/cloud-left.svg";
import CloudRight from "images/cloud-right.svg";
import { Display, CityContainer, AppMountAnimation } from "./wrappers";
import { containerAnimation } from "./animations";
import { WeatherContext } from 'context';

const Home = () => {
  const { isDay } = useContext(WeatherContext);
  return (
    <Layout isDay={isDay}>
      <AppMountAnimation
        variants={containerAnimation}
        initial="hidden"
        animate="show"
      >
        <Title />
        <Display>
          <CityContainer width="100%">
            <CurrentWeather />
            <Toggle />
            <img src={dallas} alt="Cityscape" className="city" />
            <img src={CloudLeft} alt="Cloud Left" className="cloud-left" />
            <img src={CloudRight} alt="Cloud Right" className="cloud-right" />
          </CityContainer>
          <ForecastList />
        </Display>
      </AppMountAnimation>
    </Layout>
  );
};

export default Home;
