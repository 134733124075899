import styled from 'styled-components';
import { Flex } from 'rebass';

export const LoaderWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  svg {
    max-width: 60px;
    width: 100%;
  }
`;