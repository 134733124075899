import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Box } from "rebass";
import { WeatherContext } from "context";
import groomForecastData from "utils/groomForecastData";
import identifyIcon from "utils/identifyIcon";
import isZipCode from "utils/isZipCode";
import Loader from "components/Loader";
import WeatherIcon from "components/WeatherIcon";
import PATHS from "./paths";
import { containerAnimation, itemAnimation } from "./animations";
import {
  ForecastListWrapper,
  ForecastListAnimator,
  ForecastListItem,
  ForecastListItemAnimator,
  DayContainer,
  Day,
  MobileDayContainer,
  MobileDay,
  Temp,
  WeatherIconContainer,
  TempContainer,
  ErrorContainer,
} from "./wrappers";

const ForecastList = () => {
  const { units, location } = useContext(WeatherContext);

  const [forecastData, setForecastData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(false);
    setLoading(true);
    setForecastData(null);

    // dynamic logic to handle either zipcode or location name
    let locationQuery, path;
    if (isZipCode(location)) {
      locationQuery = location;
      path = PATHS.byZipCode({ locationQuery, units });
    } else {
      const [city, state, country] = location.split(",");
      locationQuery = `${city}${!!state && "," + state}${
        !!country && "," + country
      }`;
      path = PATHS.byLocationName({ locationQuery, units });
    }

    const getWeatherInfo = () => {
      axios
        .get(path)
        .then((response) => response.data)
        .then((data) => {
          setForecastData(groomForecastData(data.list));
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    };

    getWeatherInfo();
  }, [location, units]);

  const isLoading = Boolean(loading && !error);
  const isError = Boolean(!loading & !!error);
  const isReady = Boolean(!loading && !error);

  return (
    <ForecastListWrapper width="100%">
      {/* Loading State */}
      {isLoading && (
        <ForecastListAnimator className="loader">
          <Loader />
        </ForecastListAnimator>
      )}

      {/* Ready State */}
      {isReady && (
        <ForecastListAnimator
          variants={containerAnimation}
          initial="hidden"
          animate="show"
        >
          {forecastData.map((f, idx) => {
            const icon = identifyIcon(f.description);
            return (
              <ForecastListItem key={idx.toString()}>
                <ForecastListItemAnimator
                  variants={itemAnimation}
                  initial="hidden"
                  animate="show"
                >
                  {/* Show Day Shortened on Desktop */}
                  <DayContainer>
                    <Day>{f.day || ""}</Day>
                  </DayContainer>
                  {/* Show Full Day Name on Mobile */}
                  <MobileDayContainer>
                    <MobileDay>{f.mobileDay || ""}</MobileDay>
                  </MobileDayContainer>
                  <WeatherIconContainer>
                    <WeatherIcon icon={icon} />
                  </WeatherIconContainer>
                  <TempContainer>
                    <Temp>
                      {units === "imperial"
                        ? Math.round(f.temp)
                        : Math.round(f.temp * 10) / 10}
                      <span className="degree">°</span>
                    </Temp>
                  </TempContainer>
                </ForecastListItemAnimator>
              </ForecastListItem>
            );
          })}
        </ForecastListAnimator>
      )}

      {/* Error State */}
      {isError && (
        <ErrorContainer>
          <Box>
            <WeatherIcon icon="sad" />
          </Box>
          <Box maxWidth="400px">
            <Box>
              <Day>
                Zip code / Location not found. Please try a different location.
                If entering a location name, please make sure to use commas. For example:
                Chicago, IL.
              </Day>
            </Box>
          </Box>
        </ErrorContainer>
      )}
    </ForecastListWrapper>
  );
};

ForecastList.propTypes = {
  forecast: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      temp: PropTypes.number,
    })
  ),
};

export default ForecastList;
