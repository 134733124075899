import React, { useContext, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { FaMapMarkerAlt } from "react-icons/fa";
import { WeatherContext } from "context";
import {
  TitleWrapper,
  TitleAnimator,
  Container,
  PinDropContainer,
  City,
  FormContainer,
  Form,
  DateContainer,
  DateText,
  CityContainer,
} from "./wrappers";
import { containerAnimation } from "./animations";

const Title = () => {
  const date = format(Date.now(), "EEEE, MMM d, yyyy");
  const { city, country, setLocation } = useContext(WeatherContext);
  const [location, setLocationState] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    // only allow for submit if there is an entry
    if (location.length > 0) {
      setLocation({ location });
      setIsEditing(false);
      setLocationState("");
    }
  };

  const handleFocusInput = (event) => {
    setIsEditing(true);
    inputRef.current.focus();
  }
  return (
    <TitleWrapper>
      <Helmet>
        <title>Forecast | {city.split(",")[0] || ""}</title>
      </Helmet>
      <TitleAnimator
        variants={containerAnimation}
        initial="hidden"
        animate="show"
        className="animator"
      >
        <Container className="container">
          <CityContainer>
            <PinDropContainer>
              <FaMapMarkerAlt />
            </PinDropContainer>
            {/* Ensure Only City Name is shown */}
            <FormContainer>
              <City onClick={handleFocusInput} isEditing={isEditing}>
                {city.split(",")[0] || ""}{`, ${country}`}
              </City>
              <Form onSubmit={handleSubmit} isEditing={isEditing}>
                <input
                  ref={inputRef}
                  type="text"
                  value={location}
                  onChange={(event) => setLocationState(event.target.value)}
                  onBlur={() => setIsEditing(false)}
                />
              </Form>
            </FormContainer>
          </CityContainer>
          <DateContainer>
            <DateText>{date}</DateText>
          </DateContainer>
        </Container>
      </TitleAnimator>
    </TitleWrapper>
  );
};

export default Title;
