import React from "react";
import PropTypes from "prop-types";
import getIcon from "utils/getIcon";
import { WeatherIconWrapper } from "./wrappers";

const WeatherIcon = ({ icon, color }) => {
  return <WeatherIconWrapper color={color}>{getIcon(icon)}</WeatherIconWrapper>;
};

WeatherIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default WeatherIcon;
