import {
  WiDayCloudy,
  WiDayRain,
  WiDaySunny,
  WiSnow,
} from "react-icons/wi";
import { FaRegSadCry } from "react-icons/fa"

const getIcon = (icon) => {
  switch (icon) {
    case "sad":
      return <FaRegSadCry />;
    case "snow":
      return <WiSnow />;
    case "rain":
      return <WiDayRain />;
    case "clouds":
      return <WiDayCloudy />;
    case "sky":
    default:
      return <WiDaySunny />;
  }
};

export default getIcon;
