import styled, { css } from "styled-components";
import { Flex, Box } from "rebass";
import { motion } from "framer-motion";
import mq from "utils/mq";

export const ForecastListWrapper = styled(Flex)`
  background: #ffffff;
  height: 142px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 100%;
  display: flex;
  ${mq(
    null,
    "s"
  )(css`
    height: auto;
  `)}
`;
export const ForecastListAnimator = styled(motion.div)`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  ${mq(
    null,
    "s"
  )(css`
    flex-direction: column;
    min-height: 480px;
  `)}

  @media(max-width: 400px){
    min-height: 400px;
  }

  &.loader {
    color: #65aed5;
  }
`;

export const ForecastListItem = styled(Flex)`
  border-left: 1px solid #d8d8d8;
  &:nth-child(1) {
    border-left: none;
  }
  ${mq(
    null,
    "s"
  )(css`
    border-left: none;
  `)}
`;

export const ForecastListItemAnimator = styled(motion.div)`
  display: flex;
  width: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 48px 30px 48px;
  box-sizing: border-box;
  ${mq(
    null,
    "s"
  )(css`
    padding: 20px;
    justify-content: space-between;
    flex-direction: row;
  `)}
`;

export const DayContainer = styled(Box)`
  display: block;
  padding-bottom: 8px;
  ${mq(
    null,
    "s"
  )(css`
    display: none;
  `)}
`;

export const Day = styled.p`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
`;

export const MobileDayContainer = styled(Box)`
  display: none;
  ${mq(
    null,
    "s"
  )(css`
    display: inline;
    width: 100%;
  `)}
`;

export const MobileDay = styled.p`
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  @media(max-width: 400px){
    font-size: 16px;
  }
`;

export const WeatherIconContainer = styled(Box)`
  ${mq(
    null,
    "s"
  )(css`
    display: inline;
    width: 100%;
    padding-bottom: 0;
  `)}

  @media(max-width: 400px){
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const TempContainer = styled(Box)`
  ${mq(
    null,
    "s"
  )(css`
    display: inline;
    width: 100%;
  `)}
  
  @media(max-width: 400px){
    max-width: 120px;
  }
`;

export const Temp = styled.p`
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  ${mq(
    null,
    "s"
  )(css`
    font-size: 32px;
    text-align: right;
  `)}
  @media(max-width: 400px){
    font-size: 28px;
  }

  .degree {
    font-size: 20px;
    font-weight: 600;
    ${mq(
      null,
      "s"
    )(css`
      font-size: 28px;
      text-align: right;
    `)}
    @media(max-width: 400px){
      font-size: 22px;
    }
  }
`;

export const ErrorContainer = styled(Flex)`
  padding: 20px 48px 30px 48px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  text-align: center;

  p {
    line-height: 1.2;
  }

  svg {
    color: #4a4a4a;
    width: 24px;
    height: 24px;
    margin: 10px 0;
  }
`;
