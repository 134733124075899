import styled, { css } from "styled-components";
import { Flex } from "rebass";
import mq from "utils/mq";

export const ToggleWrapper = styled(Flex)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 26px 28px;
  ${mq(null,"s")(css`
    padding: 26px 28px 26px 10px;
  `)}
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const ToggleContainer = styled(Flex)`
  position: relative;
  background: #65aed5;
  height: 26px;
  border-radius: 13px;
`;

export const TempContainer = styled(Flex)`
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 13px;
  z-index: 1;
`;

export const Temp = styled.p`
  font-size: 14px;
  font-weight: 600;
  transform: translateX(1px);
  color: ${(props) => (props.isActive ? "#2685BD" : "#ffffff")};
`;
export const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  top: 2px;
  z-index: 0;
  position: absolute;
  background: #ffffff;
  transition: .3s transform ease-in-out;
  transform: translateX;
  ${(props) => (props.isRight ? isRightCircleVariant : isLeftCircleVariant)};
`;

const isRightCircleVariant = css`
  transform: translateX(28px);
`;
const isLeftCircleVariant = css`
  transform: translateX(2px);
`;
